<template>
  <div class="container">
    <article class="article">
      <p>
        Հարգելի Հաճախորդ, Դուք իրավունք ունեք ստանալու «ԻՆԵԿՈԲԱՆԿ» ՓԲԸ
        վերաբերյալ հետևյալ տեղեկատվությունը. «Բանկերի և բանկային գործունեության
        մասին» Հայաստանի Հանրապետության օրենքի 20-րդ հոդվածի 3-րդ մասով, 43-րդ
        հոդվածի 2-րդ մասով սահմանված տեղեկատվության պատճենները, ինչպես նաև
        «Արժեթղթերի շուկայի մասին Հայաստանի Հանրապետության օրենքի 16-րդ, 120-րդ
        հոդվածներով, 126-րդ հոդվածի 1-ին մասով սահմանված տեղեկությունները: Բանկը
        տեղեկատվությունը տրամադրում է գրավոր դիմումը ստանալուց 5 (հինգ)
        աշխատանքային օրվա ընթացքում: Գրավոր դիմումը կարող է ներկայացվել հետևյալ
        ձևերով. առձեռն` Բանկի Գլխամասային գրասենյակում կամ Բանկի ցանկացած
        մասնաճյուղում (Բանկի մասնաճյուղային ցանցին կարող եք ծանոթանալ «Քարտեզ»
        բաժնում), էլեկտրոնային հասցեով` inecobank@inecobank.am, փոստով`
        «ԻՆԵԿՈԲԱՆԿ» ՓԲԸ, ք. Երևան, Թումանյան 17 հասցեով: Տեղեկատվությունը
        տրամադրվում է դիմումի մեջ նշված եղանակով, իսկ այդ նշումի բացակայության
        դեպքում` այն եղանակով, որով ստացվել է դիմումը:. Տրամադրվող
        տեղեկատվությունը թղթային եղանակով տրամադրելու դեպքում գանձվում է վճար`
        թղթային մեկ տպագիր էջի համար` 3 ՀՀ դրամ: Փոստային առաքման դեպքում
        գանձվում են նաև փոստային առաքման ծախսերը: Էլեկտրոնային եղանակով
        տրամադրումն իրականացվում է անվճար: Հաճախորդների կողմից Բանկին
        ներկայացված դիմումների, բողոքների, դժգոհությունների, առաջարկությունների
        ընդունման, դրանց քննության և քննության արդյունքում ձևավորված պատասխանը
        հաճախորդներին տրամադրելու գործընթացը կարգավորվում է «Հաճախորդների
        բողոքների ընդունման և քննության» կարգով, որը կարող եք գտնել ստորև
        ներկայացված Լրացուցիչ փաստաթղթեր մասում։ Ձեր հարցերը, բողոքները կարող եք
        ուղղել Բանկին հետևյալ եղանակներով․ առձեռն` Բանկի Գլխամասային
        գրասենյակում կամ Բանկի ցանկացած մասնաճյուղում (Բանկի մասնաճյուղային
        ցանցին կարող եք ծանոթանալ «Քարտեզ» բաժնում), էլեկտրոնային հասցեով`
        customer.care@inecobank.am, փոստով` «ԻՆԵԿՈԲԱՆԿ» ՓԲԸ, ք. Երևան, Թումանյան
        17 հասցեով, Ներկայացնելով համապատասխան հայտ՝ կայքի «Հետադարձ կապ» բաժնից
        զանգահարելով (+374 10) 510 510 հեռախոսահամարով։ Բանկի և հաճախորդի միջև
        կնքվող գործարքից բխող պահանջները, որոնք կապված են Բանկի կողմից մատուցվող
        ծառայությունների հետ և պարունակում են տասը միլիոն ՀՀ դրամը կամ դրան
        համարժեք արտարժույթով գումարը չգերազանցող գույքային պահանջ, հաճախորդի
        կողմից կարող են ներկայացվել Ֆինանսական համակարգի հաշտարարին՝ «Ֆինանսական
        համակարգի հաշտարարի մասին» ՀՀ օրենքով սահմանված կարգով: Ֆինանսական
        համակարգի հաշտարարին կարող են դիմել ֆիզիկական անձիք, ինչպես նաև այն
        անհատ ձեռնարկատեր կամ իրավաբանական անձ հաճախորդները, որոնք, Ֆինանսական
        համակարգի հաշտարարին դիմելու պահին, Հայաստանի Հանրապետության հարկային
        օրենսգրքի համաձայն, միկրոձեռնարկատիրության սուբյեկտ համարվող անձ են:
        Ինչպես նաև տեղեկացնում ենք, որ Բանկը կնքել է ֆինանսական համակարգի
        հաշտարարի որոշումները վիճարկելու իրավունքից հրաժարվելու վերաբերյալ
        համաձայնագիր, որով հրաժարվել է գույքային պահանջի չափը 250,000 ՀՀ դրամը
        կամ համարժեք արտարժույթը և գործարքի գումարի չափը 500,000 ՀՀ դրամը կամ
        համարժեք արտարժույթը չգերազանցող պահանջներով Ֆինանսական համակարգի
        հաշտարարի կողմից Բանկի դեմ կայացրած որոշումները դատական կարգով
        վիճարկելու իրավունքից։ Ֆինանսական համակարգի հաշտարարի գրասենյակ
        Հաշտարար` Փիրուզ Սարգսյան Հասցե` ՀՀ, 0010 ք. Երևան, Մ. Խորենացի փողոց 15
        «Էլիտ Պլազա» բիզնես կենտրոն, 7-րդ հարկ
      </p>
    </article>
  </div>
</template>

<script>
export default {
  name: 'Article',
};
</script>

<style></style>
