<template>
  <div class="faq">
    <div class="container">
      <div class="faq__inner">
        <h2 class="faq__title">{{ title }}</h2>
        <div class="accordion faq_accordion">
          <div
            class="faq__accordion-item"
            v-for="question in questions"
            :key="question.id"
          >
            <button
              class="accordion__btn"
              @click="toggleAccordion(question.id)"
            >
              <div class="accrodion-left">
                <span class="accordion-title">{{ question.title }}</span>
              </div>
              <div class="accrodion-right">
                <img
                  class="accordion-arrow"
                  src="/img/arrow.342408b0.svg"
                  alt=""
                  :class="clickedQuestion === question.id ? 'rotated' : ''"
                />
              </div>
            </button>
            <div
              class="accordion__content"
              :class="clickedQuestion === question.id ? 'active' : ''"
            >
              <p class="accordion__text">{{ question.body }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="updated">{{ $t('updated') }} 28.02.2022</p>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  props: {
    title: String
  },
  data() {
    return {
      questions: [
        {
          id: '1',
          title: 'Question 1',
          body: '                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi iure fugit similique recusandae perferendis maiores eanatus mollitia doloribus, ratione cumque ex molestias fugiat exercitationem corporis soluta voluptatibus! Cupiditate libero delectus ex non dolorum nam consectetur, odio architecto nihil ad molestiae, laborum dicta fuga omnis, accusamus sed! Velit, laborum minus!'
        },
        {
          id: '2',
          title: 'Question 2',
          body: '                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi iure fugit similique recusandae perferendis maiores eanatus mollitia doloribus, ratione cumque ex molestias fugiat exercitationem corporis soluta voluptatibus! Cupiditate libero delectus ex non dolorum nam consectetur, odio architecto nihil ad molestiae, laborum dicta fuga omnis, accusamus sed! Velit, laborum minus!'
        },
        {
          id: '3',
          title: 'Question 3',
          body: '                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi iure fugit similique recusandae perferendis maiores eanatus mollitia doloribus, ratione cumque ex molestias fugiat exercitationem corporis soluta voluptatibus! Cupiditate libero delectus ex non dolorum nam consectetur, odio architecto nihil ad molestiae, laborum dicta fuga omnis, accusamus sed! Velit, laborum minus!'
        },
        {
          id: '4',
          title: 'Question 4',
          body: '                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi iure fugit similique recusandae perferendis maiores eanatus mollitia doloribus, ratione cumque ex molestias fugiat exercitationem corporis soluta voluptatibus! Cupiditate libero delectus ex non dolorum nam consectetur, odio architecto nihil ad molestiae, laborum dicta fuga omnis, accusamus sed! Velit, laborum minus!'
        }
      ],
      clickedQuestion: ''
    };
  },
  methods: {
    toggleAccordion(key) {
      if (this.clickedQuestion !== key) {
        this.clickedQuestion = key;
      } else {
        this.clickedQuestion = '';
      }
      console.log(this.clickedQuestion);
    }
  }
};
</script>

<style scoped>
.faq__inner {
  padding: 25px 0;
}
.faq__title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 50px;
}
.faq__accordion-item {
  padding: 15px 20px;
  position: relative;
}

.faq__accordion-item::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--teamLightGray);
  opacity: 0.7;

  bottom: 0;
  right: 0;
}
.faq .accrodion__content.active {
  margin-top: 40px;
}
.faq .accordion-title {
  font-size: 15px;
  font-weight: 900;
}
@media screen and (min-width: 320px) and (max-width: 550px) {
  .faq__title {
    font-size: 18px;
  }
  .accordion__text {
    font-size: 14px;
    line-height: 24px;
  }
  .faq__accordion-item {
    padding: 10px 5px;
  }
}
</style>
